<template>
  <div class="standard-sidebar sidebar-feedback">
    <h1>Contact and Feedback</h1>
    <p>
      Your answers to our brief feedback form will help us understand what our site does well, and what we can do to better serve users.
    </p>
    <p class="text-center">
      <a class="go" href="https://forms.selkirk.ca/view.php?id=127511" target="_blank" @click="$matomo.trackEvent('Feedback', 'Opened Form')">Take our Questionnaire</a>
    </p>
    <p>
      For general inquiries, please use the contact link below.
    </p>
      <p class="text-center">
      <a class="go" href="https://selkirk.ca/faculty/lauren-rethoret" @click="$matomo.trackEvent('Feedback', 'Email Us')" target="_blank"><i class="material-icons align-bottom mr-1"> email </i>Email Us</a>
    </p>
  </div>
</template>

<script>
const meta = {
  title: 'Feedback',
  description: 'Help us further development by providing feedback!'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-feedback button.go,
.sidebar-feedback a.go {
  display: inline-block;
  @apply m-4 font-sans bg-green text-white font-bold py-4 px-4 no-underline;
}

.sidebar-feedback button.go:hover {
  @apply bg-green-dark;
}
</style>
