<template>
  <div class="standard-sidebar sidebar-about-data">
    <article>
    <h1>About the Data</h1>
    
<p>Climate model projections for the Columbia Basin Climate Source are based on output from an ensemble of 7 statistically downscaled Global Climate Model (GCM) projections from the Coupled Model Intercomparison Project Phase 6 (Eyring et al., 2016; Mahoney et al., 2022). The model output from the GCM sources is downscaled to a finer resolution using Bias Correction/Constructed Analogues with Quantile mapping reordering, a hybrid method developed by the <a href="https://www.pacificclimate.org" target="blank">Pacific Climate Impacts Consortium</a> combining results from Bias Corrected Constructed Analogues (Maurer et al. 2010) and Quantile Delta Mapping (Canon et al. 2015). The data for the simulation period (1950-2100) is at a resolution of 300 arc-seconds or roughly 10 km. Climate projections are provided for the 2020s, 2030s, 2040s, 2050s, 2060s, 2070s, and 2080s using a 30-year average for each time period. For example, the 2020s represent 2011-2040. Historical climate is provided for the 1960s (i.e. 1951-1980) to allow comparison with 20th century climate. </p>

<p>Model output is displayed in scenarios of lower global emissions (SSP2-4.5) and higher global emissions (SSP5-8.5). The scenarios are named according to the Shared Socioeconomic Pathway (SSP) they represent and a level of radiative forcing (in W/m2) expected to result from additional GHG emissions by the end of the century (e.g., 4.5 or 8.5). The Shared Socioeconomic Pathways define scenarios for how population, education, technology, etc. may change over the next century, influencing greenhouse gas emissions. Within each, a range of radiative forcing rates are possible, depending on how socioeconomic conditions translate to climate policies that reduce GHGs. The SSP2 scenario is considered “middle of the road” and resembles a continuation of current development patterns. The SSP5 scenario reflects “fossil-fueled development” and is characterized by policies focused on free markets and economic growth. For more information about the SSPs and how they relate to the RCP scenarios used in past versions of Climate Source, see <a href="https://climatedata.ca/resource/understanding-shared-socio-economic-pathways-ssps/" target="blank">climatedata.ca</a>.</p>

<p>Only the most optimistic SSP scenario (SSP1-1.9, which is not shown on Climate Source) describes a world in which it is possible to achieve the commitments of the <a href="https://www.un.org/en/climatechange/paris-agreement" target="blank">Paris Agreement</a>, thereby keeping global temperature rise to less than 2 degrees Celsius above pre-industrial levels. Global emissions are still moving along a trajectory that could lead to 3-5°C of warming by the end of the century, highlighting the significant gap between the emission reductions pledged by Paris Agreement signatories and the reductions required to meet the 2°C target (e.g. see Knutti et al., 2016). Consequently, it is important to consider a range of global emissions scenarios when planning for climate change in the Basin.</p>

    <h3 class="pt-4">References</h3>
    <ul class=" references">
      <li>Canon A.J., Sobie S.R., and T.Q. Murdok (2015). Precipitation by Quantile Mapping: How Well Do Methods Preserve Changes in Quantiles and Extremes?. <em>Journal of Climate</em>, <b>28(17)</b>, 6938-6959.</li>
      <li>Eyring V., Bony S., Meehl G.A., Senior C., Stevens B., Stouffer R.J., and K.E. Taylor. (2016). Overview of the Coupled Model Intercomparison Project Phase 6 (CMIP6) experimental design and organization. <em>Geoscientific Model Development</em>, <b>9(5)</b>, 1937–1958.</li>      
      <li>Knutti R., Rogelj J., Sedláček J. and E. M. Fischer. (2016). A scientific critique of the two-degree climate change target. <em>Nature Geoscience</em>, <b>9</b>, 13–18.</li>
      <li>Mahony, C.; Wang, T.; Hamann, A.; Cannon, A. (2022). A global climate model ensemble for downscaled monthly climate normals over North America. <em>International Journal of Climatology</em>, 13 Feb 2022.</li>
      <li>Maurer E., Hidalgo H., Das T., Dettinger M., and D. Cayan. (2010) The utility of daily large-scale climate data in the assessment of climate change impacts on daily streamflow in California. <em>Hydrology and Earth System Sciences</em>, <b>14(6)</b>, 1125–1138.</li>
    </ul>
    </article>
  </div>
</template>

<script>
const meta = {
  title: 'About the Data',
  description: 'View the climate data and find out more information about how it was generated.'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  }
}
</script>

<style lang="scss">
.sidebar-about-data {
  li {
    @apply py-1;
  }
}

.references {
  li {
    @apply py-1;
    word-break: break-word;
  }
}
</style>
