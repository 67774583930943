<template>
  <div class="standard-sidebar sidebar-about">
    <article>

    <h1>About Columbia Basin Climate Source</h1>

    <p>Columbia Basin Climate Source is a one-stop shop for climate change information relevant to communities in the Columbia Basin-Boundary region. It provides fine-scale climate projection data for Basin-Boundary communities, regionally-relevant impacts information, a database of local climate action examples, resources to inspire further action, and clear guidance on how to make sense of climate science.</p>
    <p>The site was produced via a partnership between Columbia Basin Trust’s Climate Action Program and Selkirk Innovates, and made possible with funding from the Columbia Basin Trust and Selkirk College researchers developing the site.</p>
  
    <p><em>Selkirk College acknowledges the First Nations of the West Kootenay and Boundary regions on whose traditional territories we are honoured to operate: the Sn̓ʕay̓čkstx (Sinixt), the Syilx (Okanagan), the Ktunaxa, and the Secwépemc (Shuswap). Columbia Basin Climate Source also extends to the traditional territory of the Lheidli T’enneh First Nation. We are grateful to these Nations as keepers of the lands and value their efforts to enhance climate resilience across the region.</em></p>

    <div class="pt-3">
      <div class="text-center pt-4"><img class="about-logo" src="@/assets/images/logo_cbt.png" alt="Columbia Basin Trust Logo"></div>
      <div class="text-center pt-4"><a href="https://selkirk.ca/innovates" target="_blank"><img class="about-logo" src="@/assets/images/logo_selkirk-innovates.png" alt="Selkirk College: Selkirk Innovates"></a></div>
      <a href="http://crcteam.ca/"><div class="text-center pt-4"><img src="@/assets/images/logo_crc.png" alt="CRC Logo" class="about-logo crc"></div></a>
    </div>

    <h2>How to Cite Us</h2>
    <p>
      Please cite the entire website as:
    </p>
      <blockquote>Selkirk College. 2021. “Columbia Basin Climate Source.” https://basinclimatesource.ca.</blockquote>

      <p>For citing climate data:</p>

      <blockquote>Climatic Resources Consulting, and Selkirk College. 2021. “Basin Climate Source: Climate Data.” https://basinclimatesource.ca/about-data.</blockquote>

    <h2>Disclaimer</h2>
    <p>Information made accessible through Columbia Basin Climate Source has been assembled by qualified researchers, but is provided without warranty as to its accuracy or completeness. Selkirk College, Columbia Basin Trust, or any other organization or individual involved in generation of content or development of the site will not be liable for any loss or damage arising from reliance on this information.</p>
    </article>
  </div>
</template>

<script>
const meta = {
  title: 'About the Site',
  description: 'Information about this website\'s development'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
blockquote {
  margin: 1rem 0rem 1rem 1rem;
}
.sidebar-about {
  .about-logo {
    max-width: 300px;
    margin: auto;
    max-height: 70px;
    min-width: 0;
    object-fit: scale-down;
  }
  .crc {
    max-width: 180px;
  }
  .logo-small {
    min-width: 0;
    object-fit: scale-down;
  }
}

article > img {
  margin: unset;
}
</style>
