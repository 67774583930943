<template>
  <div class="standard-sidebar sidebar-privacy">
    <article>
      <h1>Privacy Statement</h1>
      <p>
        In order to understand usage patterns and inform future development, this website uses a third party service to analyse web traffic data associated with your visit. The data collected via this service includes the following types of information.
      </p>
      <p>
        <ul>
          <li>The pages you accessed on this site and the links you clicked on</li>
          <li>The date and time you visited the site</li>
          <li>The referring site (if any) via which you arrived at this site</li>
        </ul>
      </p>
      <p>
        Columbia Basin Climate Source uses Matomo as a third party service to collect this data. No personal information (such as your name, mailing address, or email address) is collected.
      </p>

    </article>
  </div>
</template>

<script>
const meta = {
  title: 'Privacy Statement',
  description: 'The Basin Climate Source\'s Privacy Policy'
}

export default {
  metaInfo () {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  }
}
</script>

<style lang="scss">
.sidebar-privacy {
  li {
    @apply py-1;
  }
}
</style>
